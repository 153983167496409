<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="siparisDurumSelect"
    :options="selectionSiparisDurumlariList"
    class="w-100"
    :reduce="val => val.value"
    @input="(val) => $emit('update:siparisDurumSelect', val)" />
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions';

export default {
  props: {
    siparisDurumSelect: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      selectionSiparisDurumlariList: StaticOptions.siparisDurumlari,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
